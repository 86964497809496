// @import "./_commons.scss";
@import "../../css/_mabinso";
.form{
	.row > div:last-child{
		h4{
			margin:$twoTimes 0 $twoTimes 0;
		}
	}
	&:last-child{
		border-color:#f00;
	}
	label{
		@include mabinsoFont($fontColorDefault, 10px, $threeTimes);
		display:block;
		margin:0 0 0 $twoTimes;
		padding:$oneTimes 0;
	}
	h4{
		@include mabinsoFont($fontColorDefault, 11px, 13px);
		font-weight: bold;
		border-bottom:solid 1px $colorCorporateLightBrown;
		margin:$twoTimes $fourTimes $twoTimes 0;
		padding:$twoTimes $twoTimes $twoTimes $twoTimes;
	}
	> .row h4{
  	    padding:0 $twoTimes $twoTimes $twoTimes;
        margin:0 $fourTimes $twoTimes 0!important;
	}
	.validationError{
    	color: $colorError;
    	margin-bottom: $twoTimes;
    	&.top{
        	margin-left: $twoTimes;
    	}
	}
	input[type="text"]{
		@extend .textinputStandard;
	}
	.timePickerWrapper{
		display:inline-block;
		& >div{
			display:inline-block;
			vertical-align: top;
		}
		input[type="text"]{
			width:calc(100% - 5px);
		}
		label{
			text-overflow: ellipsis;
			margin: 0px $oneTimes 0px 0px;
			display:inline-block;
			width: 30px;
			overflow: hidden;
		}
		.react-datepicker__day--disabled{
			opacity:0.4;
		}
	}
}
@media only screen and (max-width: 720px) {
	.form{
		input[type="text"]{
			margin-left:10px;
		}
		.Select{
			margin:0 $fourTimes 0 $twoTimes;
			width:calc(100% - 20px);
		}
		.datePickerWrapper{
			.Select{
				margin-bottom:$oneTimes;
			}
			label{
				width:100%;
				margin: 0px $oneTimes 0px $twoTimes;
			}
		}
		.timePickerWrapper{
			.Select{
				margin-bottom:$oneTimes;
			}
			label{
				width:100%;
				margin: 0px $oneTimes 0px $twoTimes;
			}
		}
	}
}
