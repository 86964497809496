@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";
@import "../../css/_simple-grid";

.modalDialog{
	.modalHeader{
		background:$colorCorporateBlue;
		border:solid 1px $noColor;
		h4{
			@include mabinsoFont($noColor, 12px, 30px);
			font-weight:normal;
			padding-left:$threeTimes;
		}
		.close{
			@extend .mabinsoRoundCorner;
			@include mabinsoBgImageCentered($colorCorporateLightBlue, './close_ffffff.svg');
			background-position: center center;
			background-repeat:no-repeat;
			border: solid 1px $noColor;
			cursor: pointer;
			display:inline-block;
			height:22px;
			right:$oneTimes;
			overflow: hidden;
			position: absolute;
			text-indent:-1000px;
			top:5px;
			width:22px;
			&:hover{
				@include mabinsoBgImageCentered($colorCorporateBlue, './close_ffffff.svg');
			}
			&:active{
				@include mabinsoBgImageCentered($noColor, './close_0a193a.svg');
			}
		}
	}
	.modalBody{
		background:$noColor;
		padding:$twoTimes $fourTimes 0 $fourTimes;
		// overflow:auto;
		overflow:inherit;
		// max-height:calc(100vH - 140px);
		p{
  		  @include mabinsoFont($fontColorDefault, 13px, 16px);
  		  margin-top:10px;
		}
		hr{
			margin-top:$twoTimes;
		}
		h5{
			@include mabinsoFont($fontColorDefault, 13px, 16px);
			background-image:url('./info.svg');
			background-size:17px 17px;
			background-repeat: no-repeat;
			border-bottom:solid 1px $borderColorStandard;
			font-weight:normal;
			padding-bottom:$oneTimes;
			padding-left:$fiveTimes;
		}
		.iconCol{
            margin-bottom: 0px;
            svg{
    		    fill: $colorCorporateTurquoise;
                width:90%;
  		    }
		}
	}
	.modalFooter{
		background-image:url('./bg_cuttedcorner_left_bottom_ffffff.svg');
		background-position: bottom left;
		background-size: 1920px 1080px;
		height:$eightTimes;
		padding-right:$fourTimes;
		padding-top:$twoTimes;
		text-align:right;
	}
}
