@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";

.DocumentHr{
	 background: $darkbrown;
	 width: "calc(100vw - 60px)";
	 border:0;
	 height:1px;
	 margin:0 auto;
}
