// @import "./_sizes.scss";

$mabinsoBorderMidGray: solid 1px $colorMidGray;


.mabinsoRoundCorner{
  -webkit-border-radius: 3px!important;
     -moz-border-radius: 3px!important;
      -ms-border-radius: 3px!important;
          border-radius: 3px!important;
}
.mabinsoRoundCornerDouble{
  -webkit-border-radius: 6px!important;
     -moz-border-radius: 6px!important;
      -ms-border-radius: 6px!important;
          border-radius: 6px!important;
}
.mabinsoCircle{
  -webkit-border-radius: 2000px!important;
     -moz-border-radius: 2000px!important;
      -ms-border-radius: 2000px!important;
          border-radius: 2000px!important;
}
.mabinsoNoTextSelect{
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.clearBoth{
	clear:both;
}

// Lines
.hrDarkBrown{
	background:$darkbrown;
	border:none;
	height:1px;
}
.hrMidGray{
	background:$borderColorStandard;
	border:none;
	height:1px;
}

.textinputStandard{
  @extend .mabinsoRoundCorner;
  @include mabinsoFont($fontColorDefault, 11px, $threeTimes);
  border:solid 1px $borderColorStandard;
  height:$fiveTimes;
  margin:0 $fourTimes $oneTimes 0;
  padding: 0 $oneTimes;
  width:calc(100% - 30px);
  box-sizing: border-box;
  &:focus{
    background-color:$colorCorporateLightTurquoise!important;
    border-color:$colorCorporateTurquoise;
    outline: none;
  }
}
