@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";



.TableOfContents{
	border-right: dotted 1px $colorMidGray;
	height:calc(100% - 66px);
	overflow: auto;
	padding: $oneTimes;
	padding:31px $oneTimes $oneTimes $oneTimes;
	text-align: left;
	position: relative;
	top:31px;
	.sortHolder{
		@extend .mabinsoNoTextSelect;
		background:rgba(255, 255, 255, 0.9);
		height:30px;
		left:0px;
		padding-top: $twoTimes;
		padding-left: $oneTimes;
		position: fixed;
		top:131px;
		left:0;
		width:calc(25% - 5px);
		z-index:2;
		label{
			display:inline-block;
			height:20px;
			line-height:20px;
			vertical-align: middle;
			margin-left:$oneTimes;
			margin-right:$oneTimes;
			max-width:20%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.chapterHolder{
		padding-top:10px;
	}
}
