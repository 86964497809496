.Checkbox {
    display: inline-block;
    position: relative;
    top: 6px;
    width: 24px;
    height: 24px;

    .tick {
        stroke-dasharray: 24;
        stroke-dashoffset: 24;
        transition: stroke-dashoffset .5s cubic-bezier(1,0,.55,.41);
    }

    .square {
        fill-opacity: 0;
        transition: fill-opacity .18s ease-in-out, stroke .18s ease-in-out;
    }

    &.checked {
        .tick {
            stroke-dashoffset: 0;
            //transition-delay: .12s;
        }
        .square {
            fill-opacity: 1;
        }
    }

    &.unchecked {
        .tick {
            stroke-dashoffset: 24;
        }
        .square {
            fill-opacity: 0;
            //transition-delay: .6s;
        }
    }
}
