@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";

@mixin backgroundButton22Icon($color1, $color2, $img, $shaddow){
	background: $color1; /* Old browsers */
	background-image: url($img), -moz-linear-gradient(top, $color1 0%, $color2 100%)!important; /* FF3.6-15 */
	background-image: url($img), -webkit-linear-gradient(top, $color1 0%,$color2 100%)!important; /* Chrome10-25,Safari5.1-6 */
	background-image: url($img), linear-gradient(to bottom, $color1 0%,$color2 100%)!important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	background-position: center center;
	background-repeat:no-repeat;
	background-size:22px 22px;
	box-shadow: $shaddow;
}

$buttonShaddow: 0 0 2px $colorMidLightGray;

.Button22Icon{
	@extend .mabinsoRoundCorner;
	border:solid 1px $fontColorDefault;
	color: $fontColorDefault;
	display:inline-block;
	height:22px;
	margin-left: $oneTimes;
	overflow: hidden;
	padding: 0;
	text-indent:-1000px;
	width:22px;
	&.minus{
		@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/minus_ffffff.svg', $buttonShaddow);
		&:active{
			@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/minus_ffffff.svg', none);
		}
		&:hover{
			@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/minus_ffffff.svg', none);
		}
	}
	&.delete{
		@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22delete.svg', $buttonShaddow);
		&:hover{
			@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22delete.svg', none);
		}
		&:active{
			@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/icon22delete.svg', none);
		}
	}
	&.download{
		@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22download.svg', $buttonShaddow);
		&:hover{
			@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22download.svg', none);
		}
		&:active{
			@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/icon22download.svg', none);
		}
	}
	&.view{
		@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22view.svg', $buttonShaddow);
		&:hover{
			@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22view.svg', none);
		}
		&:active{
			@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/icon22view.svg', none);
		}
	}
	&.edit{
		@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22edit.svg', $buttonShaddow);
		&:hover{
			@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22edit.svg', none);
		}
		&:active{
			@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/icon22edit.svg', none);
		}
	}
	&.add{
		@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22add.svg', $buttonShaddow);
		&:hover{
			@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22add.svg', none);
		}
		&:active{
			@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/icon22add.svg', none);
		}
	}
	&.expand{
		@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22expand.svg', $buttonShaddow);
		&:hover{
			@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22expand.svg', none);
		}
		&:active{
			@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/icon22expand.svg', none);
		}
	}
	&.contract{
		@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22contract.svg', $buttonShaddow);
		&:hover{
			@include backgroundButton22Icon($noColor, $colorMidGray, '../../images/icon22contract.svg', none);
		}
		&:active{
			@include backgroundButton22Icon($colorMidGray, $noColor, '../../images/icon22contract.svg', none);
		}
	}
}
