@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";

.ButtonText{
	@extend .mabinsoNoTextSelect;
	@extend .mabinsoRoundCorner;
	@include mabinsoFont($colorLightBlack, 11px, 24px);
	@include mabinsoGradientStandard($noColor, $colorMidGray);
	border:solid 1px $fontColorDefault;
	box-shadow: 0 0 2px $colorMidLightGray;
	color: $fontColorDefault;
	height:26px;
	margin-left: $oneTimes;
	padding: 0 $oneTimes;
	&.fullWidth{
		width:100%;
		margin:0px;
	}
	&:hover{
		box-shadow: none;
	}
	&:active{
		@include mabinsoGradientStandard($colorMidGray, $noColor);
		box-shadow: none;
	}
	&:disabled{
    	opacity:0.5;
	}
}
