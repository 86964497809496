@import "../../../../css/_colors";
@import "../../../../css/_mixins";
@import "../../../../css/_sizes";
@import "../../../../css/_superstandards";
@import "../../../../css/_simple-grid";


:global(.ReactTable){
    line-height: 26px;
	border:none;
	// margin-top:-10px;
	// margin-left:-10px;
	// margin-right:-10px;
	// margin-left:3px;
  
	:global(.rt-thead){
		&:global(.-header){
			background:$colorCorporateLightBrown;
			:global(.rt-resizable-header-content){
				text-align:left;
				color:$noColor;
			}
		}
		:global(.rt-th), :global(.rt-td){
			border-right:solid 1px $colorCorporateBrown;
			border-bottom:solid 1px $colorCorporateBrown;
			&:global(:last-child){
				border-right:none;
			}
		}
		&:global(.-filters){
			:global(.rt-tr){
				background: $colorLightGray;
				&:hover{
					background: $colorMidDarkGray;
				}
			}
			:global(.rt-th){
				border-right:solid 1px $borderColorStandard;
				border-bottom: solid 1px $borderColorStandard;
				padding: 2px;
				:global(input){
					@extend .mabinsoRoundCorner;
					border:solid 1px $borderColorStandard;
					height:20px;
					font-size:12px;
					padding: 0 5px;
					@include mabinsoFont($colorDarkGray, 10px, 15px);
				}
			}
		}

	}
	&:global(.-striped){
		:global(.rt-tr){
			&:global(.-odd){
				@include mabinsoGradientStandard($noColor, $colorLightGray);
				&:global(.selected) {
                    @include mabinsoGradientStandard($colorCorporateLightTurquoise, $colorCorporateLightTurquoise);
                }
			}
			&:global(.-even){
				background:$noColor;
				&:global(.selected) {
                    background: $colorCorporateLightTurquoise;
                }
			}
		}
	}
	&:global(.-highlight){
		:global(.rt-tbody){
			:global(.rt-tr:not(.-padRow):hover){
				background:$colorCorporateLightTurquoise!important;
			}
		}
	}
	:global(.rt-tbody){
		:global(.rt-tr-group){
			border-bottom:solid 1px $borderColorStandard;
		}
	}
	:global(.-pagination){
		height:30px;
		background:$colorCorporateLightBrown;
		border-top:solid 1px $colorCorporateBrown;
		border-bottom:solid 1px $colorCorporateBrown;
		padding:1px 1px;
		:global(.-pageInfo){
			color:$noColor;
      margin: 0 10px;
		}
		:global(.-btn){
			@extend .mabinsoRoundCorner;
			border:solid 1px $fontColorDefault;
			box-shadow: 0 0 2px $colorMidLightGray;
			color: $fontColorDefault;
			@include mabinsoGradientStandard($noColor, $colorMidGray);
			height:26px;
			&:global(:hover){
				box-shadow: none;
				color: $fontColorDefault;
			}
			&:global(:active){
				@include mabinsoGradientStandard($colorMidGray, $noColor);
				color: $fontColorDefault;
			}
			&:global(:not([disabled]):hover){
				color: $fontColorDefault;
			}
		}
	}
    :global(.-pagination){
        :global(.-previous), :global(.-next){
                flex: 0.3 1 0%;
        }
    }

}
