$noColor:#fff;

// Grundfarben
$darkbrown:#7D6F53;
$colorCorporateBrown:#7D6F53;
$colorCorporateLightBrown:#AC9C79;
$colorCorporateTurquoise:#01aad3;
$colorCorporateMidTurquoise:#6CBBD8;
$colorCorporateLightTurquoise:#E2F1F7;
$colorCorporateDarkBlue:#0A193A;
$colorCorporateBlue:#0a193a;
$colorCorporateLightBlue:#003567;

// Grau
$colorLightGray:#eee;
$colorMidLightGray:#aaa;
$colorMidGray:#ccc;
$colorMidDarkGray:#ddd;
$colorDarkGray:#808080;

// Schwarz
$colorLightBlack:#333;

// Status
$colorError:#d40000;
$colorMidError:#d86c6b;

// Text-Farben
$fontColorDefault:$colorLightBlack;

// Rahmen-Farben
$borderColorStandard:$colorMidGray;
