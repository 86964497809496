@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";

@mixin backgroundButton20IconCircle($color, $img){
	background-color:$color;
	background-image: url($img);
	background-position: center center;
	background-repeat:no-repeat;
	background-size:20px 20px;
}

.Button20IconCircle{
	@extend .mabinsoCircle;
	border: solid 1px $colorCorporateTurquoise;
	display:inline-block;
	height:20px;
	margin-right:$oneTimes;
	overflow:hidden;
	text-indent:-2000px;
	width:20px;
	&.delete{
		@include backgroundButton20IconCircle($colorCorporateLightTurquoise, '../../images/icon20delete_i.svg');
		&:active{
			@include backgroundButton20IconCircle($colorCorporateTurquoise, '../../images/icon20delete_a.svg');
		}
	}
	&.edit{
		@include backgroundButton20IconCircle($colorCorporateLightTurquoise, '../../images/icon20edit_i.svg');
		&:active{
			@include backgroundButton20IconCircle($colorCorporateTurquoise, '../../images/icon20edit_a.svg');
		}
	}
}
