@import "../../css/_colors";
@import "../../css/_sizes";


.validationError{
    color: $colorError;
    margin-bottom: $twoTimes;
    &.top{
        margin-left: $twoTimes;
    }
    &.bubble{
      position: absolute;
      margin-top:-8px;
      padding: 0 5px;
      color: $noColor;
      background: $colorError;
      width: 100%;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      z-index:1;
      .label{
        width:100%;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .arrow{
        border-top: solid 10px transparent;
        border-right: solid 10px transparent;
        border-left: solid 10px transparent;
        border-bottom: solid 10px $colorError;
        width: 0px;
        height: 0px;
        position: absolute;
        top: -20px;
        right: 0px;
      }
    }
}
