@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";
@import "../../css/_simple-grid";


.colorpicker{
    .swatch{
        @extend .mabinsoRoundCorner;
        border:solid 1px $borderColorStandard;
        height:$fiveTimes;
        width:$fiveTimes;
    }
    .pickerHolder{
        @extend .mabinsoRoundCorner;
        border:solid 1px $borderColorStandard;
        position:absolute;
        background: #fff;
        width: 150px;
        z-index: 1000;
        padding: $twoTimes;
        text-align:right;
        .slider{
            margin-bottom: $twoTimes;
        }
    }
}
