@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";

.documentHolder{
  @include gradient($noColor, #eee);
  min-height:100%;
  position: fixed;
  z-index: 1002;
  left: 0;
  right: 0;
  top:0px;
  .documentHead{
    @include noselect;
    font-family: $font-family;
    font-size: $font-size;
    text-align:left;
    margin: 0;
    background:#fff;
    //position: fixed;
    z-index:2001;
    height: 30px;
    width:100%;
    position: absolute;
    top:0px;
    background-color:$colorCorporateDarkBlue;
    background-image:inline-image('./img/bgCorporate.svg');
    background-position: center center;
    background-repeat:no-repeat;
    background-size:cover;
    cursor: default;
    box-shadow: 0px 1px 5px #808080;
    .documentTitle{
      margin: 0;
      background-image:inline-image('./img/logo.svg');
      background-size:auto 16px;
      background-repeat:no-repeat;
      background-position:10px 7px;
      color:$noColor;
      height:30px;
      line-height:30px;
      padding: 0 0 0 10px;
    }
    .studioInfo{
      position:absolute;
      top:0px;
      right:45px;
      a{
        color:$colorCorporateTurquoise;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
      div{
        color:$noColor;
        display:inline-block;
        line-height:30px;
        margin-left:20px;
        &.username{
          background-image:inline-image('./img/user_i.svg');
          background-repeat:no-repeat;
          background-position:left center;
          padding-left:20px;
          &:hover{
            background-image:inline-image('./img/user_a.svg');
          }
        }
      }
    }
    .documentClose{
      @include button-icon('./img/modalClose_i.svg', center, center);
      display:block;
      outline:none;
      border:none;
      height:28px;
      overflow:hidden;
      position: absolute;
      right:3px;
      top:1px;
      text-align:left;
      text-indent:-1000px;
      width:28px;
      &:hover{
        @include button-icon('./img/modalClose_h.svg', center, center);
      }
      &:focus, &:active{
        @include button-icon('./img/modalClose_a.svg', center, center);
      }
    }
  }
  .documentContent{
//     height:calc(100% -31px);
    display: block;
    position: absolute;
    top:31px;
    width:100vW;
    overflow: hidden;
    .documentPadding{
      flex-shrink: 0;
      padding-top: 30px;
    }
    .documentContentInner{
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
