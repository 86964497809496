$font-family:"Lucida Grande","Lucida Sans Unicode","Lucida Sans","Tahoma";
$font-size:12px;
$borderRadiusDefault:3px;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin mabinsoGradientStandard($color1, $color2){
	background: $color1; /* Old browsers */
	background: -moz-linear-gradient(top, $color1 0%, $color2 100%)!important; /* FF3.6-15 */
	background: -webkit-linear-gradient(top, $color1 0%,$color2 100%)!important; /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, $color1 0%,$color2 100%)!important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 )!important; /* IE6-9 */
}
@mixin mabinsoBgImageCentered($color1, $img){
	background-color: $color1;
	background-image: url($img);
	background-repeat:no-repeat;
	background-position: center center;
}
@mixin mabinsoGradientStandardImage($color1, $color2, $img){
	background: $color1; /* Old browsers */
	background-image: url($img), -moz-linear-gradient(top, $color1 0%, $color2 100%)!important; /* FF3.6-15 */
	background-image: url($img), -webkit-linear-gradient(top, $color1 0%,$color2 100%)!important; /* Chrome10-25,Safari5.1-6 */
	background-image: url($img), linear-gradient(to bottom, $color1 0%,$color2 100%)!important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	background-repeat:no-repeat;
}
@mixin mabinsoFont($color, $size, $lineHeight){
	color: $color;
	font-family:"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Tahoma";
	font-size: $size;
	line-height:$lineHeight;
}
@mixin gradient($color1, $color2) {
  background:-webkit-linear-gradient($color1 0%, $color2 100%);
  background:linear-gradient($color1 0%, $color2 100%);
  background:-moz-linear-gradient($color1 0%, $color2 100%);
  background-attachment: fixed;
}
@mixin buttongradient($color1, $color2) {
  background:-webkit-linear-gradient($color1 0%, $color2 100%);
  background:linear-gradient($color1 0%, $color2 100%);
  background:-moz-linear-gradient($color1 0%, $color2 100%);
}
@mixin noselect{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@mixin button-icon($img, $left, $top) {
  background:url($img);
  background-position: $left $top;
  background-repeat: no-repeat;
  background-color:transparent;
  color: $noColor;
}
/* Defaults */
@mixin borderRadiusDefault{
  @include border-radius($borderRadiusDefault);
}
@mixin fontDefault{
  font-family:$font-family;
  font-size:11px;
}
