@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";
@import "../../css/_simple-grid";

.Filter{
	position: fixed;
	z-index:10;
	background-color: $colorLightGray;
	border-bottom: $mabinsoBorderMidGray;
	height: $sixTimes;
	width: 100%;
	&:hover{
		background-color: $colorMidDarkGray;
	}
	p{
		@include mabinsoFont($colorDarkGray, 11px, 30px);
		margin-left:5px;
		margin-right:5px;
		&.info{
			padding-left:25px;
			background-image:url('./info.svg');
			background-size:20px 20px;
			background-repeat: no-repeat;
			background-position: 0px 5px;
		}
	}
}
