@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";

.TableStandard{
	table{
		border-collapse:collapse;
		width:100%;
		thead{
			@extend .mabinsoNoTextSelect;
			tr{
				background-color:$colorCorporateLightBrown;
				border-bottom:solid 1px $colorCorporateBrown;
				th{
					@include mabinsoFont($noColor, 11px, $fourTimes);
					border-left:solid 1px $colorCorporateBrown;
					cursor:pointer;
					text-align:left;
					font-weight:normal;
					padding: 0 $twoTimes;
					&:first-child{
						border-left:none;
					}
					&.orderby{
						font-weight: bold;
						padding-left:10px;
						&.ASC{
							background-image:url('./arrowASC_ffffff.svg');
							background-repeat: no-repeat;
							background-position:$twoTimes 8.5px;
							background-size: 8px 4px;
							padding-left:23px;
						}
						&.DESC{
							background-image:url('./arrowDESC_ffffff.svg');
							background-repeat: no-repeat;
							background-position:$twoTimes 8px;
							background-size: 8px 4px;
							padding-left:23px;
						}
					}
					&.actions{
						cursor:not-allowed;
					}
				}
			}
		}
		tbody{
			tr{
				@include mabinsoFont($colorDarkGray, 11px, 14px);
				&:nth-child(odd) {
					@include mabinsoGradientStandard($noColor, $colorLightGray);

				}
				&:nth-child(even) {
					background:$noColor;
				}
				&:hover{
					@include mabinsoGradientStandard($colorCorporateLightTurquoise, $colorCorporateLightTurquoise);
					@include mabinsoFont($fontColorDefault, 11px, 14px);
					td{
						&.actions{
							button{
								visibility:visible;
							}
						}
					}
				}
				td{
					background-image:url('./cellSeperator.svg');
					background-repeat: no-repeat;
					background-position: right bottom;
					background-size: 1px 9px;
					border-bottom:solid 1px $borderColorStandard;
					text-align:left;
					padding: 8px $twoTimes;
					vertical-align: top;
					&.date{
						text-align:left;
						white-space: nowrap;
					}
					&.string{
						text-align:left;
					}
					&.actions{
						text-align:right;
						padding:4px;
						white-space: nowrap;
						button{
							visibility:hidden;
						}
					}
					&.orderby{
						@include mabinsoFont($fontColorDefault, 11px, 14px);
					}
					&:last-child{
						background-image: none;
					}
				}
			}
		}
	}
}
