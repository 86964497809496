@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";

.ListContentHint{
	display:table;
	height:100%;
	width:100%;
	.textHolder{
		display:table-cell;
		height:100%;
		text-align: center;
		vertical-align: middle;
		width:100%;
		.textHolderInner{
			display:inline-block;;
			margin: 0 auto;
			.textBox{
				@extend .mabinsoRoundCornerDouble;
				@include mabinsoFont($colorMidLightGray, 15px, $fiveTimes);
				background:$colorLightGray;
				border:solid 1px $colorMidGray;
				display:table-cell;
				height:200px;
				padding:20px;
				vertical-align: middle;
				width:200px;
			}
		}
	}
}
